<template>
  <div>
    <Pipeline></Pipeline>
  </div>

</template>

<script>
import Pipeline from "@/components/Pipeline/Pipeline.vue";
import BaseView from "@/template/BaseView.vue";

export default {
  components: {
    Pipeline,
    BaseView,
  },
  created() {
    if (this.$store.getters.user.user.level == 'templater' || this.$store.getters.user.user.level == 'blogger' || this.$store.getters.recursos.ncrm == 0) {this.$router.push("/dynamicRoute/AccessDenied")};
  },
};
</script>
